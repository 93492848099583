/* Light Mode */
:root[color-mode="light"] {
    --surface-a: #ffffff;
    --surface-b: #fafafa;
    --surface-c: rgba(0, 0, 0, 0.04);
    --surface-d: rgba(0, 0, 0, 0.12);
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --surface-overlay: rgba(0, 0, 0, 0.32);
    --text-color: rgba(0, 0, 0, 0.87);
    --text-color-reverse: rgba(255, 255, 255, 0.87);
    --text-color-secondary: textSecondaryColor;
    --primary-color: #a52031;
    --primary-color-highlight: rgba(165, 32, 49, 0.7);
    --primary-color-highlight-text: rgba(255, 255, 255, 0.87);
    --primary-color-text: #000000;
    --font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
    --surface-0: #ffffff;
    --surface-50: #fafafa;
    --surface-100: #f5f5f5;
    --surface-200: #eeeeee;
    --surface-300: #e0e0e0;
    --surface-400: #bdbdbd;
    --surface-500: #9e9e9e;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --content-padding: 1rem;
    --inline-spacing: 0.5rem;
}
/* Dark Mode */
:root[color-mode="dark"] {
    --surface-a: var(--surface-c);
    --surface-b: #121212;
    --surface-c: hsla(0, 0%, 100%, 0.04);
    --surface-d: hsla(0, 0%, 100%, 0.12);
    --surface-e: var(--surface-c);
    --surface-f: #262626;
    --surface-overlay: rgba(0, 0, 0, 0.32);
    --text-color: rgba(255, 255, 255, 0.87);
    --text-color-reverse: rgba(0, 0, 0, 0.87);
    --text-color-secondary: textSecondaryColor;
    --primary-color: #a52031;
    --primary-color-highlight: rgba(165, 32, 49, 0.7);
    --primary-color-highlight-text: rgba(255, 255, 255, 0.87);
    --primary-color-text: #ffffff;
    --font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
    --surface-0: #121212;
    --surface-50: #181818;
    --surface-100: var(--surface-c);
    --surface-200: #242424;
    --surface-300: #2a2a2a;
    --surface-400: #303030;
    --surface-500: #363636;
    --surface-600: #3b3b3b;
    --surface-700: #414141;
    --surface-800: #474747;
    --surface-900: #4d4d4d;
    --content-padding: 1rem;
    --inline-spacing: 0.5rem;
}
