body,
.btn {
    font-size: 1rem;
}
ol.breadcrumb {
    background-color: #ddd;
    margin-bottom: 0px;
}
li.breadcrumb-item a {
    color: #333;
    text-decoration: underline;
}
#content-main {
    padding: 0.5rem;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.sticky + .content {
    padding-top: 60px;
}
.p-datatable-thead .p-dropdown,
.p-datatable-thead .p-inputtext,
.p-datatable-thead .p-multiselect {
    width: 100%;
}
.dense-choice-field {
    padding: 2px;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.25rem 0.25rem;
}
#datatable .p-paginator .p-paginator-current {
    margin-left: auto;
}
#datatable .p-paginator {
    border: thin solid #404040;
}
